<template>
  <div class="about">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <h2 class="h2 margin">ESFM/UA</h2>
          <p class="text">
            Escuela Superior de Formación de Maestras y Maestros <br />Unidades
            Académicas
          </p>
        </div>
      </div>
    </div>
    <div class="section padding-bottom hero-greys">
      <div class="container">
        <div class="center">
          <select
            class="custom_select"
            v-model="departamento_id"
            @change="getInstituciones()"
          >
            <option value="" selected>Todos los departamentos</option>
            <option
              v-for="(item, index) in departamentos"
              :value="item.id"
              :key="index"
              >{{ item.descripcion }}</option
            >
          </select>
        </div>
        <br />
        <div class="w-dyn-list">
          <div role="list" class="posts-grid w-dyn-items">
            <div
              role="listitem"
              class="w-dyn-item"
              v-for="(item, index) in instituciones"
              :key="index"
            >
              <div class="post-card">
               
               <!--
                <img
                  src="images/salabiblioteca.jpg"
                  loading="lazy"
                  alt="Franz Tamayo"
                  class="post-image"
                />
               -->
                <img
                  :src="item.imagen ? 'images/' +  item.imagen : 'images/sala.jpg'" 
                  loading="lazy"
                  alt="Franz Tamayo"
                  class="post-image"
                />


                <div class="post-content">
                  <div class="small-text">
                    Fundación {{ item.fecha_creacion | formatdate }}
                  </div>
                  <h5 class="h5 not-margin">
                    {{ item.nombre }}
                  </h5>
                  <div class="small-text">
                    <p><b>Ubicación</b></p>
                    <p>
                      <span class="icon-arrow-right6"></span>
                      {{ item.departamento }}
                      <!-- {{ item.provincia }} -->
                    </p>
                    <p>
                      <span class="icon-arrow-right6"></span>
                      {{ item.direccion }}
                    </p>
                  </div>
                  <!-- <div class="space-top-2 small-text">
                    <p><b>Director(a) General</b></p>
                    <p>
                      <span class="icon-arrow-right6"></span>
                      Renato Molina Herrera
                    </p>
                  </div> -->
                  <div class="small-container center fixed-bottom">
                    <!-- <router-link
                      to="/esfm/detalle"
                      class="secondary-small-button w-button margin-top"
                    >
                      Ver detalle
                    </router-link> -->
                    <button
                      type="button"
                      @click="redirectDetail(item.id)"
                      class="secondary-small-button w-button margin-top"
                    >
                      Ver detalle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import axios from "axios";
export default {
  name: "esfm",
  data() {
    return {
      loading: false,
      departamento_id: "",
      departamentos: [],
      instituciones: [],
      serve: "",
    };
  },
  created() {
    Service.removeInstitutionId();
  },
  mounted() {
    this.serve = Service.getServe();
    this.getDepartamentos();
    this.getInstituciones();
  },
  methods: {
    getDepartamentos() {
      axios
        .get(Service.getBase() + "lista/departamentos", Service.getHeader())
        .then((response) => {
          this.departamentos = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    getInstituciones() {
      this.loading = true;
      axios
        .post(
          Service.getBase() + "lista/instituciones",
          { departamento_id: this.departamento_id },
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.instituciones = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    redirectDetail(institucion_id) {
      Service.setInstitutionId(institucion_id);
      this.$router.push({ name: "institution_detail" });
    },
  },
};
</script>
